import { Box, Tab, Tabs, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { PropsWithChildren, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getVisitorsHistory, listExpandedUsers, logout, useDecodedToken, User, VisitorsResponse } from '../../api'
import { ReactComponent as LogoSvg } from '../../components/images/logo.svg'
import homeStyles from '../HomePage/HomePage.module.scss'

enum AdminTabs {
  Users,
  Visitors,
}

interface TabPanelProps {
  index: number
  value: AdminTabs
}

function TabPanel(props: PropsWithChildren<TabPanelProps>) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value.valueOf() !== index} {...other}>
      {value.valueOf() === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function AdminPage() {
  const [userData, setUserData] = useState<User[] | undefined>()
  const [visitorsData, setVisitorsData] = useState<VisitorsResponse | undefined>()
  const [tab, setTab] = useState(AdminTabs.Users)
  const [visitorId, setVisitorId] = useState('')

  const decodedToken = useDecodedToken()

  const handleLogout = () => {
    logout()
  }

  const handleTabChange = (_e: SyntheticEvent, newTab: AdminTabs) => {
    setTab(newTab)
  }

  const fetchUserData = () => {
    listExpandedUsers().then((data) => {
      setUserData(data)
    })
  }

  const fetchVisitorHistory = useCallback(() => {
    if (visitorId) {
      getVisitorsHistory(visitorId).then((data) => {
        setVisitorsData(data)
      })
    }
  }, [visitorId])

  useEffect(() => {
    switch (tab) {
      case AdminTabs.Users:
        fetchUserData()
        break
      case AdminTabs.Visitors:
        fetchVisitorHistory()
        break
    }
  }, [fetchVisitorHistory, tab])

  return (
    <div className={homeStyles.home}>
      <header className={homeStyles.header}>
        <NavLink to={'/'}>
          <LogoSvg />
        </NavLink>
        <div className={homeStyles.profile}>
          <span className={homeStyles.name}>Logged in as {decodedToken?.name}</span>
          <Button component={NavLink} to={'/'}>
            Home
          </Button>
          <Button variant='outlined' className={homeStyles.logout} onClick={handleLogout}>
            Log out
          </Button>
        </div>
      </header>
      <main className={homeStyles.main}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label='Users' />
            <Tab label='Visitors' />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <pre>{JSON.stringify(userData, null, 2)}</pre>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <TextField value={visitorId} onChange={(e) => setVisitorId(e.currentTarget.value)} />
          <pre>{JSON.stringify(visitorsData?.visits, null, 2)}</pre>
        </TabPanel>
      </main>
      <footer />
    </div>
  )
}

export default AdminPage
