import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { FormEvent, useState } from 'react'
import InputMask from 'react-input-mask'
import { GenericError, useDecodedToken } from '../../api'
import { sleep } from '../../utils/common'
import { md5 } from '../../utils/crypto'
import styles from '../Forms.module.scss'

export type WithVisitorId<T> = T & {
  visitorId: string
}

export interface CardDetails {
  cardNumber: string
  cardExpiration: string
  cardholderName: string
  paymentProviderId: string
}

interface DialogProps {
  isOpen: boolean
  onSubmit: (paymentDetails: WithVisitorId<CardDetails>) => void
  onClose: () => void
}

export default function PaymentDialog({ isOpen, onSubmit, onClose }: DialogProps) {
  const decodedToken = useDecodedToken()
  const [name, setName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [expiration, setExpiration] = useState('')
  const [cvc, setCvc] = useState('')
  const [error, setError] = useState<GenericError | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const { getData } = useVisitorData({ linkedId: decodedToken?.email, ignoreCache: true }, { immediate: false })

  const handleClose = () => {
    onClose()
    clearForm()
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    // simulates sending data to payment provider
    const paymentProviderId = md5(`${name}_${cardNumber}_${expiration}_${cvc}`)

    const details: CardDetails = {
      cardholderName: name,
      cardNumber: cardNumber.slice(-4),
      cardExpiration: expiration,
      paymentProviderId: paymentProviderId,
    }

    Promise.all([getData(), sleep(1000)])
      .then(([data]) => {
        if (data?.visitorId) {
          onSubmit({ ...details, visitorId: data.visitorId })
        } else {
          setError({
            code: 'value_missing',
            param: 'visitorId',
            message: `Can't process payment without a valid fingerprint`,
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        clearForm()
      })
  }

  function clearForm() {
    setName('')
    setCardNumber('')
    setExpiration('')
    setCvc('')
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle style={{ padding: '36px 36px 20px' }}>Payment details</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent classes={{ root: styles.paymentDialog }}>
            <DialogContentText className={styles.formDescription}>
              Please enter your credit card details to gain access to thousands of hours of exclusive educational
              content.
            </DialogContentText>
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <CircularProgress />
              </div>
            ) : (
              <div className={styles.paymentForm}>
                <div className={styles.fieldContainer}>
                  <TextField
                    autoFocus
                    autoComplete='cc-csc'
                    margin='dense'
                    id='name'
                    label='Name'
                    variant='outlined'
                    type='text'
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                  />
                </div>
                <div className={styles.fieldContainer}>
                  <InputMask
                    mask='9999 9999 9999 9999'
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.currentTarget.value)}
                  >
                    <TextField
                      autoComplete='cc-csc'
                      margin='dense'
                      id='cardNumber'
                      label='Card Number'
                      variant='outlined'
                      type='text'
                      fullWidth
                      required
                    />
                  </InputMask>
                </div>
                <div className={styles.fieldContainer}>
                  <InputMask mask='99/99' value={expiration} onChange={(e) => setExpiration(e.currentTarget.value)}>
                    <TextField
                      autoComplete='cc-csc'
                      margin='dense'
                      id='cardExpiration'
                      label='Expiration (mm/yy)'
                      variant='outlined'
                      type='text'
                      required
                    />
                  </InputMask>
                </div>
                <div className={styles.fieldContainer}>
                  <InputMask mask='999' value={cvc} onChange={(e) => setCvc(e.currentTarget.value)}>
                    <TextField
                      autoComplete='cc-csc'
                      margin='dense'
                      id='cardCvc'
                      label='Security Code'
                      variant='outlined'
                      type='text'
                      required
                    />
                  </InputMask>
                </div>
                {error && <p className='error'>{error}</p>}
              </div>
            )}
          </DialogContent>
          <DialogActions className={styles.actions} classes={{ root: styles.actions }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant='contained' type='submit'>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
