import { client } from './client'

export enum UseCase {
  AccountTakeover = 'ato',
  AccountSharing = 'asp',
}

export interface AppConfig {
  useCase: UseCase
  smartSignalsEnabled: boolean
  accountsPerFingerprint: number
}

export async function getConfig(visitorId: string) {
  return client<AppConfig>(`config/${visitorId}`)
}

export async function updateConfig(visitorId: string, config: Partial<AppConfig>) {
  return client<AppConfig>(`config/${visitorId}`, false, { method: 'PUT', body: config })
}
