import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { TextField } from '@mui/material'
import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import * as api from '../../api'
import { GenericError } from '../../api'
import { Layout } from '../../components/Layout'
import { useHomepageRedirect } from '../../components/redirects'

function SignUpPage() {
  useHomepageRedirect()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<GenericError | undefined>(undefined)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    getData({ ignoreCache: true }).then((data) => {
      if (data) {
        console.log(data)
        api.signup({ name, email, password, visitorId: data.visitorId, requestId: data.requestId }).catch((e) => {
          setError(e)
        })
      }
    })
  }

  const { getData } = useVisitorData({ linkedId: email }, { immediate: false })

  return (
    <Layout>
      <section className='body'>
        <div className='auth-form container'>
          <div className='row auth-form-content'>
            <div className='card'>
              <div className='card-body'>
                <h1 className='card-title'>Sign up for free</h1>
                <p className='auth-switcher-text'>
                  Already have an account? <Link to={'/login'}>Log in</Link>
                </p>
                <p className='promo-text'>
                  Gain access to tons of educational content!
                  <br /> Sign up today for a free 14-day trial!
                </p>
                <form className='form' onSubmit={handleSubmit}>
                  <div className='form-control'>
                    <label htmlFor='email'>Name:</label>
                    <TextField
                      id='name'
                      type='text'
                      name='name'
                      variant='outlined'
                      required
                      value={name}
                      onChange={(e) => setName(e.currentTarget.value)}
                    />
                  </div>
                  <div className='form-control'>
                    <label htmlFor='email'>Email:</label>
                    <TextField
                      id='email'
                      type='email'
                      name='email'
                      variant='outlined'
                      required
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </div>
                  <div className='form-control'>
                    <label htmlFor='password'>Password:</label>
                    <TextField
                      id='password'
                      type='password'
                      name='password'
                      required
                      variant='outlined'
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                  </div>
                  <SignupError error={error} />
                  <div className='auth-button-wrapper text-center'>
                    <button className='btn btn-primary auth-button' type='submit'>
                      Create Account
                    </button>
                  </div>
                </form>
                <div className='conditions'>
                  By signing up, you agree to our <a href='#'>Terms and Conditions</a> and the{' '}
                  <a href='#'>Privacy Policy</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

function SignupError({ error }: { error?: GenericError }) {
  if (!error) {
    return null
  }

  if (error.code === 'limit_restriction' && error.param === 'visitorId') {
    return (
      <p className='error'>
        Looks like you have reached a maximum number of accounts for this device.
        <br />
        If you don&apos;t think that is the case, please reach out to us at <b>support@e-learning.com</b>.
      </p>
    )
  }

  if (error.code === 'visitor_id_flagged' && error.param === 'visitorId') {
    return (
      <p className='error'>
        The device you are using has been involved in suspicious activity, so account creation is temporary disabled.
        <br />
        If you think we got it wrong, please reach out to us at <b>support@e-learning.com</b>.
      </p>
    )
  }

  if (error.code === 'bot_detected' && error.param === 'requestId') {
    return (
      <p className='error'>
        The device you are using looks like an automated tool, so account creation is currently not available.
        <br />
        If you think we got it wrong, please reach out to us at <b>support@e-learning.com</b>.
      </p>
    )
  }

  if (error.code === 'vpn_detected' && error.param === 'requestId') {
    return (
      <p className='error'>
        Looks like you are connected to a VPN. In order to create an account, please disconnect from it and try again.
        <br />
        If you think we got it wrong, please reach out to us at <b>support@e-learning.com</b>.
      </p>
    )
  }

  if (error.code === 'tampering_detected' && error.param === 'requestId') {
    return (
      <p className='error'>
        The device you are using looks like it has been tampered with, so account creation is temporary disabled. Try
        disabling browser extensions that modify your browser.
        <br />
        If you think we got it wrong, please reach out to us at <b>support@e-learning.com</b>.
      </p>
    )
  }

  return <p className='error'>Invalid parameters</p>
}

export default SignUpPage
