import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { TextField } from '@mui/material'
import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import * as api from '../../api'
import { GenericError } from '../../api'
import AttachNewDeviceDialog from '../../components/dialogs/AttachNewDeviceDialog'
import OTPDialog from '../../components/dialogs/OTPDialog'
import { Layout } from '../../components/Layout'
import { useHomepageRedirect } from '../../components/redirects'

function LogInPage() {
  useHomepageRedirect()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<GenericError | undefined>(undefined)

  const { getData } = useVisitorData({ linkedId: email }, { immediate: false })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    getData({ ignoreCache: true }).then((data) => {
      if (data) {
        console.log(data)
        api.login({ email, password, visitorId: data.visitorId, requestId: data.requestId }).catch((e) => {
          setError(e)
        })
      }
    })
  }

  const handleSubmitOTP = (loginIntentId: string, code: string, trustDevice?: boolean) => {
    api.checkOTP({ loginIntentId, code, trustDevice }).catch((e) => {
      setError(e)
    })
  }

  return (
    <Layout>
      <section className='body'>
        <div className='auth-form container'>
          <div className='row auth-form-content'>
            <div className='card'>
              <div className='card-body'>
                <h1 className='card-title'>Log in</h1>
                <p className='auth-switcher-text'>
                  Don&apos;t have an account? <Link to={'/signup'}>Sign up</Link>
                </p>

                <form className='form' onSubmit={handleSubmit}>
                  <div className='form-control'>
                    <label htmlFor='email'>Email:</label>
                    <TextField
                      id='email'
                      type='email'
                      name='email'
                      variant='outlined'
                      required
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </div>
                  <div className='form-control'>
                    <label htmlFor='password'>Password:</label>
                    <TextField
                      id='password'
                      type='password'
                      name='password'
                      variant='outlined'
                      required
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                  </div>
                  <LoginError
                    error={error}
                    onOTPSubmit={(loginIntentId, code, trustDevice) =>
                      handleSubmitOTP(loginIntentId, code, trustDevice)
                    }
                    onOTPClose={() => {
                      setError(undefined)
                    }}
                    onAttachNewDevice={(loginIntentId, code) => handleSubmitOTP(loginIntentId, code, true)}
                  />
                  <div className='auth-button-wrapper text-center'>
                    <button className='btn btn-primary auth-button' type='submit'>
                      Log in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

function LoginError({
  error,
  onOTPSubmit,
  onOTPClose,
  onAttachNewDevice,
}: {
  error?: GenericError
  onOTPSubmit: (loginIntentId: string, code: string, trustDevice: boolean) => void
  onOTPClose: () => void
  onAttachNewDevice: (loginIntentId: string, code: string) => void
}) {
  if (!error) {
    return null
  }

  if (error.code === 'additional_verification_required') {
    const loginIntentId = error.param as string
    return (
      <OTPDialog onSubmit={(code, trustDevice) => onOTPSubmit(loginIntentId, code, trustDevice)} onClose={onOTPClose} />
    )
  }

  if (error.code === 'unknown_device') {
    const loginIntentId = error.param as string
    return <AttachNewDeviceDialog onSubmit={(code) => onAttachNewDevice(loginIntentId, code)} />
  }

  if (error.code === 'new_device_attachment') {
    return (
      <p className='error'>
        You can only attach a new device once a month. Last reattachment was on{' '}
        {Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date((error as any).lastAttachment))}.
      </p>
    )
  }

  return <p className='error'>Invalid credentials</p>
}

export default LogInPage
