import styles from './Course.module.scss'

export interface CourseProps {
  title: string
  description: string
  imageUrl: string
  tags: string[]
}

export interface CourseBoxProps extends CourseProps {
  onEnroll: () => void
}

export function CourseBox({ title, description, imageUrl, onEnroll }: CourseBoxProps) {
  return (
    <div
      className={styles.courseBox}
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5)), url("${imageUrl}")`,
      }}
    >
      <div className={styles.textContent}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      <button className={styles.actionButton} onClick={() => onEnroll()}>
        Enroll
      </button>
    </div>
  )
}

export function CourseGrid({
  courses,
  tags = [],
  onEnroll,
}: {
  courses: CourseProps[]
  tags: string[]
  onEnroll: () => void
}) {
  const preferredCourses: CourseProps[] = []
  const otherCourses: CourseProps[] = []

  courses.forEach((course) => {
    const hasIntersection = Array.from(new Set(tags)).filter((tag) => new Set(course.tags).has(tag)).length

    if (hasIntersection) {
      preferredCourses.push(course)
    } else {
      otherCourses.push(course)
    }
  })

  return (
    <>
      {preferredCourses.length > 0 ? (
        <>
          <div className={styles.courseContainerTitle}>
            <h2>Courses you might like</h2>
          </div>
          <div className={styles.courseContainer}>
            <div className={styles.courseGrid}>
              {preferredCourses.map((course) => (
                <CourseBox key={course.title} {...course} onEnroll={onEnroll} />
              ))}
            </div>
          </div>
        </>
      ) : null}
      <div className={styles.courseContainerTitle}>
        <h2>Featured Courses</h2>
      </div>
      <div className={styles.courseContainer}>
        <div className={styles.courseGrid}>
          {otherCourses.map((course) => (
            <CourseBox key={course.title} {...course} onEnroll={onEnroll} />
          ))}
        </div>
      </div>
    </>
  )
}
