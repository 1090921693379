import { client, deleteAuthorization } from './client'

export const JWT_KEY = 'appToken'

export interface User {
  id: number
  name: string
  role: UserRole
  status: UserStatus
  email: string
}

export interface LoginRequest {
  email: string
  password: string
  visitorId?: string
  requestId?: string
}

export interface SignupRequest extends LoginRequest {
  name: string
}

export interface OTPRequest {
  loginIntentId: string
  code: string
  trustDevice?: boolean
}

export interface AuthResponse {
  token: string
}

export async function login(params: LoginRequest) {
  return client<AuthResponse>('login', false, { method: 'POST', body: params }).then((data) => {
    if (data.token) {
      localStorage.setItem(JWT_KEY, data.token)
      window.location.replace('/')
    }
  })
}

export async function signup(params: SignupRequest) {
  return client<AuthResponse>('signup', false, { method: 'POST', body: params }).then(({ token }) => {
    if (token) {
      localStorage.setItem(JWT_KEY, token)
      window.location.replace('/')
    }
  })
}

export async function checkOTP(params: OTPRequest) {
  return client<AuthResponse>('login/otp', false, { method: 'POST', body: params }).then((data) => {
    if (data.token) {
      localStorage.setItem(JWT_KEY, data.token)
      window.location.replace('/')
    }
  })
}

export function listExpandedUsers() {
  return client<User[]>('users')
}

export async function getUser(userId: string) {
  return client<User>(`users/${userId}`)
}

export function logout() {
  deleteAuthorization()
}

export enum UserRole {
  Admin = 'admin',
  Premium = 'premium',
  Free = 'free',
}

export enum UserStatus {
  Active = 'active',
  Suspended = 'suspended',
}

export interface AuthToken {
  id: string
  role: UserRole
  status: UserStatus
  name: string
  email: string
}
