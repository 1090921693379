import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { useState } from 'react'

interface DialogProps {
  onSubmit: (code: string) => void
  onClose?: () => void
}

export default function AttachNewDeviceDialog({ onSubmit, onClose }: DialogProps) {
  const [open, setOpen] = useState(true)
  const [otp, setOtp] = useState('')

  const handleClose = () => {
    onClose?.()
    setOpen(false)
  }

  const handleSubmit = () => {
    onSubmit(otp)
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Attach new device</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Looks like you are trying to log in from an unknown device. You can use only one device to access your
            account. If you want to make this device your primary one, please enter a security code we&apos;ve sent to
            your mobile phone. <br />
            <b>You can only reattach a device once a month.</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='code'
            label='Security code'
            type='text'
            fullWidth
            variant='standard'
            onChange={(e) => setOtp(e.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant='contained'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
