import { VisitorData } from '@fingerprintjs/fingerprintjs-pro-spa'
import { client } from './client'

export interface VisitorsResponse {
  visitorId: string
  visits: VisitorData[]
}

export async function getVisitorsHistory(visitorId: string) {
  return client<VisitorsResponse>(`fingerprint/visitors/${visitorId}`)
}

export async function getEventData(requestId: string) {
  return client<VisitorsResponse>(`fingerprint/events/${requestId}`)
}
