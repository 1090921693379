import { Box, Modal, Typography } from '@mui/material'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 440,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

export function AccountSuspendedModal({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2' style={{ color: 'red' }}>
          Account suspended
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          Your account has been suspended for suspicious activity. Please contact us at <b>support@elearning.com</b>.
        </Typography>
      </Box>
    </Modal>
  )
}
