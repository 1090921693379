import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { AppConfig, getConfig, updateConfig as saveConfig, UseCase } from '../api/config'

export interface AppConfigData {
  config: AppConfig
  updateConfig: (visitorId: string, newConfig: Partial<AppConfig>) => Promise<void>
}

export const getDefaultConfig = () => ({
  useCase: UseCase.AccountTakeover,
  smartSignalsEnabled: true,
  accountsPerFingerprint: 1,
})

const AppConfigContext = createContext<AppConfigData>({
  config: getDefaultConfig(),
  updateConfig: async () => undefined,
})

export const useConfig = () => useContext(AppConfigContext)

export function AppConfigProvider({ children }: PropsWithChildren<{}>) {
  const [config, setConfig] = useState(getDefaultConfig())
  const isFirstRender = useRef(true)
  const { data } = useVisitorData()

  useEffect(() => {
    async function fetchConfig() {
      if (isFirstRender.current && data) {
        const config = await getConfig(data.visitorId)
        console.log(config)
        setConfig(config)
        isFirstRender.current = false
      }
    }

    fetchConfig()
  }, [data, isFirstRender])

  const updateConfig = async (visitorId: string, newConfig: Partial<AppConfig>) => {
    const savedConfig = await saveConfig(visitorId, newConfig)
    setConfig(savedConfig)
  }

  return <AppConfigContext.Provider value={{ config, updateConfig }}>{children}</AppConfigContext.Provider>
}
