import jwtDecode from 'jwt-decode'
import { useMemo } from 'react'
import { API_URL } from '../utils/env'
import { AuthToken, JWT_KEY } from './users'

export type GenericError = {
  code: string
  param?: string
  message?: string
}

export async function client<T extends any>(
  endpoint: string,
  authorization = true,
  { method, headers = {}, body }: { method: 'GET' | 'POST' | 'PUT' | 'DELETE'; headers?: any; body?: any } = {
    method: 'GET',
    headers: {},
  }
) {
  if (authorization) {
    headers['Authorization'] = `Bearer ${getAuthorization()}`
  }

  const newHeaders = { 'content-type': 'application/json', ...headers }
  const config: any = {
    method,
    headers: newHeaders,
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  const url = `${API_URL}/${endpoint}`
  const response = await fetch(url, config)
  if (response.ok) {
    const json = await response.json()

    if (!json.ok && json.error) {
      return Promise.reject(json.error as GenericError)
    }

    return json.data as T
  } else {
    const errorMessage = await response.text()

    if (response.status === 401) {
      deleteAuthorization()
      return Promise.reject(new Error('Unauthorized'))
    }

    return Promise.reject(new Error(errorMessage))
  }
}

export function getAuthorization() {
  return localStorage.getItem(JWT_KEY)
}

export function deleteAuthorization(redirect = true) {
  localStorage.removeItem(JWT_KEY)

  if (redirect && window.location.pathname !== '/login') {
    window.location.replace('/login')
  }
}

export function useDecodedToken() {
  const token = getAuthorization() ?? ''

  const tokenData = useMemo(() => jwtDecode(token), [token])
  return tokenData as AuthToken | null
}
