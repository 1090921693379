import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import clsx from 'clsx'
import { PropsWithChildren, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import * as api from '../api'
import { UseCase } from '../api'
import { useConfig } from '../context/config'
import { ReactComponent as LogoSvg } from './images/logo.svg'
import styles from './Layout.module.scss'

export function Layout({ children }: PropsWithChildren<any>) {
  const { config, updateConfig } = useConfig()
  const { data } = useVisitorData()
  const [isAdminControlOpen, setIsAdminControlOpen] = useState(false)

  const handleResetDatabase = () => {
    setIsAdminControlOpen(false)
    api.resetDatabase().catch((e) => {
      console.error('Database reset request failed', e)
    })
  }

  const [appConfig, setAppConfig] = useState(config)

  useEffect(() => {
    setAppConfig(config)
  }, [config])

  const handleUpdateConfig = () => {
    if (data && appConfig) {
      setIsAdminControlOpen(false)
      updateConfig(data.visitorId, appConfig)
    }
  }

  return (
    <div className='wrapper'>
      <div className='header'>
        <NavLink to={'/'}>
          <LogoSvg />
        </NavLink>
      </div>
      <main>
        <div className='main'>{children}</div>
      </main>
      <footer>
        <Accordion className={styles.adminControls} classes={{ root: styles.root }} expanded={isAdminControlOpen}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon className={styles.arrow} onClick={() => setIsAdminControlOpen(!isAdminControlOpen)} />
            }
            classes={{ expanded: styles.expanded }}
          >
            <></>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.section}>
              <FormControl>
                <FormLabel>Use case</FormLabel>
                <RadioGroup
                  row
                  name='use-case'
                  value={appConfig.useCase}
                  onChange={(_event, value) => {
                    setAppConfig({ ...appConfig, useCase: value as UseCase })
                  }}
                  className={styles.radioGroup}
                >
                  <FormControlLabel
                    value={UseCase.AccountSharing}
                    control={<Radio size='small' />}
                    label='Account sharing'
                  />
                  <FormControlLabel
                    value={UseCase.AccountTakeover}
                    control={<Radio size='small' />}
                    label='Account takeover'
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={clsx(styles.limits, styles.section)}>
              <FormLabel>Limits</FormLabel>
              <div className={styles.limitEntry}>
                <span>Accounts per fingerprint</span>
                <TextField
                  className={styles.value}
                  type='number'
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={appConfig.accountsPerFingerprint}
                  onChange={(e) =>
                    setAppConfig({ ...appConfig, accountsPerFingerprint: parseInt(e.currentTarget.value) })
                  }
                />
              </div>
            </div>
            <div className={clsx(styles.section, styles.integrations)}>
              <FormLabel>Integrations</FormLabel>
              <div className={styles.checkboxGroup}>
                <FormControlLabel
                  control={<Checkbox checked={appConfig.smartSignalsEnabled} size='small' />}
                  label='Smart Signals'
                  onChange={(_event, value) => {
                    setAppConfig({ ...appConfig, smartSignalsEnabled: value })
                  }}
                />
              </div>
            </div>
            <div className={styles.buttonGroup}>
              <Button onClick={handleResetDatabase} variant='outlined'>
                Reset demo
              </Button>
              <Button onClick={handleUpdateConfig} variant='contained'>
                Save config
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </footer>
    </div>
  )
}
