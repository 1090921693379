import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Snackbar } from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  createPayment,
  getUser,
  getVisitorsHistory,
  JWT_KEY,
  logout,
  useDecodedToken,
  UserRole,
  UserStatus,
} from '../../api'
import { deleteVisitorData, getVisitor as getVisoVisitor } from '../../api/viso'
import { CourseGrid, CourseProps } from '../../components/course/Course'
import PaymentDialog, { CardDetails } from '../../components/dialogs/PaymentDialog'
import { ReactComponent as LogoSvg } from '../../components/images/logo.svg'
import { AccountSuspendedModal } from '../../components/modals/AccountSuspendedModal'
import { VISO_INTEGRATION_ENABLED } from '../../utils/env'
import styles from './HomePage.module.scss'

interface PaymentResult {
  ok: boolean
  error?: string
}

function HomePage() {
  const decodedToken = useDecodedToken()
  const userId = decodedToken!.id
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false)
  const [paymentResult, setPaymentResult] = useState<PaymentResult | null>(null)
  const [isSuspended, setIsSuspended] = useState(decodedToken?.status === UserStatus.Suspended)
  const [isSuspendedModalOpen, setIsSuspendedModalOpen] = useState(isSuspended)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const { data } = useVisitorData({ linkedId: decodedToken?.email })

  const [tags, setTags] = useState<string[]>([])
  const [visitorId, setVisitorId] = useState('')

  useEffect(() => {
    if (data?.visitorId) {
      setVisitorId(data.visitorId)

      if (VISO_INTEGRATION_ENABLED) {
        getVisoVisitor(data.visitorId).then((data) => {
          const uniqTags = Array.from(new Set(data.tags))
          setTags(uniqTags)
        })
      }

      getVisitorsHistory(data.visitorId).then((data) => {
        console.log(data)
      })
    }
  }, [data])

  const handleLogout = () => {
    logout()
  }

  const handleCloseAlert = () => {
    setIsAlertOpen(false)
  }

  const handleUpgrade = () => {
    getUser(userId).then((user) => {
      const isSuspended = user.status === UserStatus.Suspended
      setIsSuspended(isSuspended)
      isSuspended ? setIsSuspendedModalOpen(true) : setIsPaymentDialogOpen(true)
    })
  }

  const handlePaymentSubmit = (cardDetails: CardDetails) => {
    createPayment(userId, cardDetails)
      .then((data) => {
        console.log(data)
        localStorage.setItem(JWT_KEY, data.token)
        setIsAlertOpen(true)
        setPaymentResult({
          ok: true,
        })
      })
      .catch((e) => {
        setIsAlertOpen(true)
        setPaymentResult({
          ok: false,
          error: e.message ?? `Your payment wasn't successful. Error code: ${e.code}`,
        })
      })
      .finally(() => {
        setIsPaymentDialogOpen(false)
      })
  }

  const handlePaymentCancel = () => {
    setIsPaymentDialogOpen(false)
  }

  const handleClose = () => {
    setIsSuspendedModalOpen(false)
  }

  const handleDeletePersonalization = () => {
    deleteVisitorData(visitorId).catch((e) => {
      console.error(e)
    })
  }

  return (
    <>
      <div className={styles.home}>
        <header className={styles.header}>
          <NavLink to={'/'}>
            <LogoSvg />
          </NavLink>
          <div className={styles.profile}>
            <span className={styles.name}>
              Logged in as {decodedToken?.name}
              {decodedToken?.role === UserRole.Premium ? <WorkspacePremiumIcon /> : ''}
            </span>
            {decodedToken?.role === 'admin' && (
              <Button component={NavLink} to={'/admin'}>
                Admin
              </Button>
            )}
            {decodedToken?.role === UserRole.Free && (
              <Button variant='contained' className={styles.upgrade} onClick={handleUpgrade}>
                Upgrade
              </Button>
            )}
            <Button variant='outlined' className={styles.logout} onClick={handleLogout}>
              Log out
            </Button>
          </div>
        </header>
        <main className={styles.main}>
          <Snackbar open={isAlertOpen} autoHideDuration={5000} onClose={handleCloseAlert}>
            {paymentResult?.ok ? (
              <Alert severity='success' onClose={handleCloseAlert}>
                <AlertTitle>Success</AlertTitle>
                Your payment was successful, enjoy the content!
              </Alert>
            ) : (
              <Alert severity='error' onClose={handleCloseAlert}>
                <AlertTitle>Error</AlertTitle>
                {paymentResult?.error}
              </Alert>
            )}
          </Snackbar>
          <CourseGrid courses={courses} tags={tags} onEnroll={handleUpgrade} />
        </main>
        <PaymentDialog isOpen={isPaymentDialogOpen} onSubmit={handlePaymentSubmit} onClose={handlePaymentCancel} />
        <footer>
          {VISO_INTEGRATION_ENABLED ? (
            <Accordion className={styles.adminControls} classes={{ root: styles.root }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ expanded: styles.expanded }}>
                <></>
              </AccordionSummary>
              <AccordionDetails>
                <Button onClick={handleDeletePersonalization} variant='contained'>
                  Delete personalization
                </Button>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </footer>
      </div>
      <AccountSuspendedModal isOpen={isSuspendedModalOpen} handleClose={handleClose} />
    </>
  )
}

export default HomePage

export const courses: CourseProps[] = [
  {
    title: 'Cat parent',
    description: 'Learn to take care of a rescue cat',
    imageUrl: 'cat.jpg',
    tags: ['animals', 'cats'],
  },
  {
    title: 'Blues guitar',
    description: `Delta blues beginner's course`,
    imageUrl: 'colours.jpg',
    tags: ['music', 'guitar'],
  },
  {
    title: 'Camp like a pro',
    description: 'Essential skills in the wild',
    imageUrl: 'fire.jpg',
    tags: ['nature', 'camping'],
  },
  {
    title: 'Hiking',
    description: 'Finding the best mountainous trails',
    imageUrl: 'mountains.jpg',
    tags: ['nature', 'camping'],
  },
  {
    title: 'Foraging',
    description: 'How to tell edible mushrooms from poisonous',
    imageUrl: 'mushroom.jpg',
    tags: ['nature', 'food'],
  },
  {
    title: 'Gardening',
    description: 'Growing amazing vegetables in your garden',
    imageUrl: 'pumpkin.jpg',
    tags: ['nature', 'garden', 'plants'],
  },
  {
    title: 'Stone skipping',
    description: 'Cool skill for meditation and party tricks',
    imageUrl: 'stones.jpg',
    tags: ['nature', 'meditation'],
  },
  {
    title: 'Bird watching',
    description: 'Discover amazing birds in your area',
    imageUrl: 'bird.jpg',
    tags: ['nature', 'birds'],
  },
  {
    title: 'Succulents',
    description: 'Top 10 unpretentious plants for your home',
    imageUrl: 'succulents.jpg',
    tags: ['nature', 'plants'],
  },
  {
    title: 'Making pasta',
    description: 'Learn to make unforgettable home pasta',
    imageUrl: 'pasta.jpg',
    tags: ['cooking', 'food'],
  },
  {
    title: 'Frontend development',
    description: 'React.js - the most popular Javascript framework',
    imageUrl: 'code.jpg',
    tags: ['coding', 'code', 'software', 'engineering'],
  },
  {
    title: 'Jazz guitar',
    description: 'Jazz guitar fundamentals',
    imageUrl: 'guitar.jpg',
    tags: ['guitar', 'music'],
  },
  {
    title: 'Woodworking',
    description: 'Create beautiful handcrafted furniture',
    imageUrl: 'woodworking.jpg',
    tags: ['diy', 'woodworking'],
  },
  {
    title: 'Housework',
    description: `You'll never need a pro for simple home repairs`,
    imageUrl: 'housework.jpg',
    tags: ['diy', 'repairs'],
  },
  {
    title: 'Leatherworking 101',
    description: 'Learn leather crafting',
    imageUrl: 'leatherwork.jpg',
    tags: ['diy', 'leather'],
  },
]
