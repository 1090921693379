import { Checkbox, FormControlLabel } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { useState } from 'react'

interface DialogProps {
  onSubmit: (code: string, trustDevice: boolean) => void
  onClose: () => void
}

export default function OTPDialog({ onSubmit, onClose }: DialogProps) {
  const [open, setOpen] = useState(true)
  const [otp, setOtp] = useState('')
  const [trustDevice, setTrustDevice] = useState(false)

  const handleClose = () => {
    onClose()
    setOpen(false)
  }

  const handleSubmit = () => {
    onSubmit(otp, trustDevice)
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Additional verification required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Looks like you are trying to log in from an unknown device. Please enter a security code we&apos;ve sent to
            your mobile phone.
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='code'
            label='Security code'
            type='text'
            fullWidth
            variant='standard'
            onChange={(e) => setOtp(e.currentTarget.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  setTrustDevice((trustDevice) => !trustDevice)
                }}
              />
            }
            label='Add this device as trusted'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant='contained'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
