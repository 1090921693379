import { CardDetails } from '../components/dialogs/PaymentDialog'
import { client } from './client'

export interface PaymentMethod {
  id: string
  cardNumber: string
  cardExpiration: string
  paymentProviderId: string
  cardholderName: string
  userId: string
}

export async function createPayment(userId: string, params: CardDetails) {
  return client<{ paymentMethod: PaymentMethod; token: string }>(`users/${userId}/payments`, true, {
    method: 'POST',
    body: params,
  })
}
