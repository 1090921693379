import { client } from './client'

export namespace Viso {
  export interface Visitor {
    tags: string[]
  }
}

export async function getVisitor(visitorId: string) {
  return client<Viso.Visitor>(`viso/visitors/${visitorId}`)
}

export async function deleteVisitorData(visitorId: string) {
  return client(`viso/visitors/${visitorId}`, true, { method: 'DELETE' })
}
